
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { sameAs } from 'vuelidate/dist/validators.min';

import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import ApiError from 'Entities/ApiError';
import StakingApi from 'Apis/Staking';
import RedeemRequest from 'Entities/staking/RedeemRequest';
import Button from 'Control/Button.vue';
import Checkbox from 'Control/Checkbox.vue';
import AssetValue from 'UI/AssetValue.vue';
import Icon from 'UI/Icon.vue';

interface Data {
    position: any;
    isAgreedWithConditions: boolean;
    hasValidationError: boolean;
}

interface Methods {
    toggleConditions: () => void;
    completeWithdraw: () => void;
    dateMaker: (data: string) => string;
}

interface Computed {
    activeAccountId: string;
    interest: number;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Button,
        Checkbox,
        AssetValue,
        Icon,
    },
    data() {
        return {
            position: null,
            isAgreedWithConditions: false,
            hasValidationError: false,
        };
    },
    validations() {
        return {
            isAgreedWithConditions: {
                sameAs: sameAs(() => true),
            },
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        interest() {
            return (Number(this.position.rewardAmount) / Number(this.position.accrualDays)) || 0;
        },
    },
    methods: {
        toggleConditions() {
            this.isAgreedWithConditions = !this.isAgreedWithConditions;
        },
        async completeWithdraw() {
            if (this.position.status.toUpperCase() !== 'HOLDING') {
                await this.$store.dispatch('Notificator/showErrorNotification', 'You could not redeem this position');
                return;
            }
            this.hasValidationError = false;
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await StakingApi.redeem(new RedeemRequest({
                        accountId: this.activeAccountId,
                        placementName: this.position.placementName,
                        positionId: this.position.uid,
                    }));
                    await this.$router.push('/earn').catch(() => { /* navigation error */ });
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during completing withdrawal');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else {
                this.hasValidationError = true;
            }
        },
        dateMaker(dateStr) {
            const date = new Date(dateStr);
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
    },
    mounted() {
        const { position } = this.$route.query;
        if (position) {
            this.position = JSON.parse(position as string);
        }
    },
});
